import {
  useCallAction,
  useEvent,
  useStore,
} from '@cobuildlab/react-simple-state';
import { FC, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { fetchSubscriptionsByPriceEvent } from '../../../subscription/subscription-events';
import { formatCurrencyMXN } from '../../../../shared/utils';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { getSubscriptionTotalDateFilterTitleText } from '../../dashboard-utils';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { useAuth } from '../../../auth/auth-hook';
import hexToRgba from 'hex-to-rgba';
import { useStylesCustom } from '../DashboardCardItem';
import { DashboardSubscriptionByPriceTable } from './DashboardSubscriptionByPriceTable';
import { fetchSubscriptionsByPrice } from '../../../subscription/subscription-actions';
import { subscriptionTableFilterStore } from '../../../subscription/subscription-store';

export const DashboardSubscriptionByPrice: FC = () => {
  const subscriptionsByPrice = useEvent(fetchSubscriptionsByPriceEvent);
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const { tenant } = useAuth();
  const classes = useStylesCustom();
  const { status, paymentMethod } = useStore(subscriptionTableFilterStore);
  const pageSize = 10;
  const [page, setPage] = useState(0);

  const [callFetchSubscriptionsByPrice, loading] = useCallAction(
    fetchSubscriptionsByPrice,
  );

  useEffect(() => {
    if (tenant) {
      callFetchSubscriptionsByPrice({
        tenant,
        from,
        to,
        status,
        paymentMethod,
      });
    }
  }, [
    callFetchSubscriptionsByPrice,
    from,
    tenant,
    to,
    status,
    paymentMethod,
  ]);

  const data = useMemo(() => {
    const dataByTitle = subscriptionsByPrice.data;
    const totalEarnings = subscriptionsByPrice.totalEarnings;

    const labels = Object.keys(dataByTitle).map(
      (title) => `${title} (${dataByTitle[title].count})`,
    );
    const series = Object.values(dataByTitle).map((item) => item.total);
    const counts = Object.values(dataByTitle).map((item) => item.count);

    const tableItems = Object.keys(dataByTitle).map((title) => ({
      id: crypto.randomUUID(),
      title,
      count: String(dataByTitle[title].count),
      total: formatCurrencyMXN(dataByTitle[title].total),
      isTotal: false,
    }));

    const dataPaginated = tableItems.slice(
      page * pageSize,
      (page + 1) * pageSize,
    );

    dataPaginated.push({
      id: crypto.randomUUID(),
      title: 'Total',
      count: String(subscriptionsByPrice.totalCount),
      total: formatCurrencyMXN(totalEarnings),
      isTotal: true,
    });

    const chartData = {
      labels,
      series,
      counts,
      totalEarnings,
    };

    const tableData = {
      count: tableItems.length,
      data: dataPaginated,
    };

    return { chartData, tableData };
  }, [page, subscriptionsByPrice]);

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'pie',
    },
    labels: data.chartData.labels,
    colors: [
      tenant?.identityColor,
      '#FFCC0F',
      '#000',
      'rgba(50,50,50,0.8)',
      'rgba(50,50,50,0.5)',
      '#92C5FC',
      hexToRgba(tenant?.identityColor as string, 0.75),
    ],
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: formatCurrencyMXN,
      },
    },
  };

  return (
    <Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
          my: '16px',
        }}>
        <Typography fontSize={20} fontWeight={700}>
          {getSubscriptionTotalDateFilterTitleText(from, to, filter)}:{' '}
          {formatCurrencyMXN(data.chartData.totalEarnings)}
        </Typography>
      </Box>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        alignItems="stretch">
        <Grid
          item
          xs={12}
          md={6}
          order={{
            xs: 2,
            md: 1,
          }}>
          <Card
            elevation={2}
            style={{
              border: '1px solid #BBBBBB',
              padding: '16px',
              borderRadius: '16px',
              boxShadow: '0px 0px 8px 0px #00000014',
              flexGrow: 1,
              height: 'calc(100% - 32px)',
            }}>
            <CardContent className={classes.content}>
              <DashboardSubscriptionByPriceTable
                data={data.tableData.data}
                loading={loading}
                page={page + 1}
                total={data.tableData.count}
                pageSize={pageSize}
                onPageChange={setPage}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{
            xs: 1,
            md: 2,
          }}>
          <Card
            elevation={2}
            style={{
              border: '1px solid #BBBBBB',
              padding: '16px',
              borderRadius: '16px',
              boxShadow: '0px 0px 8px 0px #00000014',
              height: 'calc(100% - 32px)',
            }}>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                fontWeight: 800,
                pb: '16px',
              }}>
              {getSubscriptionTotalDateFilterTitleText(from, to, filter)}
            </Typography>
            <CardContent className={classes.content}>
              <Chart
                options={options}
                series={data.chartData.series}
                type="pie"
                width={'100%'}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
