import React from 'react';
import {
  Theme,
  Drawer as MuiDrawer,
  Menu as MuiMenu,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  IconButton,
  Avatar,
  MenuItem,
  Typography,
  listItemClasses,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import HomeIcon from '@mui/icons-material/Home';
//import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsIcon from '@mui/icons-material/Settings';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '@cobuildlab/react-simple-state';
import { drawerSidebarEvent } from './layout-events';
import { DRAWER_WIDTH, MIN_DRAWER_WIDTH } from '../../constants';
import { CSSObject, styled } from '@mui/material/styles';
import { RoleAuthorizationAdmin } from '../../../modules/rbac/RoleAuthorization';
import { RBACPermissions } from '../../../modules/rbac/rbac-types';
import { useAuth } from '../../../modules/auth/auth-hook';
import { openDrawerSidebar } from './layout-actions';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import {
  CardMembershipOutlined as PlanIcon,
  Summarize as ReportIcon,
} from '@mui/icons-material';

type StylesProps = {
  drawerWidth: number;
  minDrawerWidth: number;
};

const useStyles = makeStyles<Theme, StylesProps>((theme) =>
  createStyles({
    drawer: {
      margin: '16px',
      boxShadow: '0px 0px 44px 0px #00000029 !important',
      borderRadius: '16px',
      zIndex: 10,
      [theme.breakpoints?.down('md')]: {
        position: 'fixed',
      },
      [theme.breakpoints?.up('md')]: {
        /**
         *
         * @param {object} props - Styles Props.
         * @param {boolean} props.drawerWidth - Drawer Width.
         * @returns {number} - Drawer Width.
         */
        position: 'relative',
        flexShrink: 0,
        transition: theme.transitions.create(['width', 'transform'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    drawerPaper: {
      /**
       *
       * @param {object} props - Styles Props.
       * @param {boolean} props.drawerWidth - Drawer Width.
       * @returns {number} - Drawer Width.
       */
      position: 'absolute',

      alignItems: 'center',

      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints?.down('md')]: {
        position: 'fixed',
      },
    },
    listItem: {
      height: 44,
      borderRadius: '99px!important',
      display: 'flex!important',
      justifyContent: 'center!important',
      marginBottom: '5px!important',
    },
    versionText: {
      color: 'rgba(0, 0, 0, 0.22)',
      fontWeight: 700,
      fontSize: 12,
      textTransform: 'uppercase',
    },
  }),
);

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    width: DRAWER_WIDTH - 50,
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: MIN_DRAWER_WIDTH,
  [theme.breakpoints.up('md')]: {
    width: MIN_DRAWER_WIDTH,
  },
});

const ROUTES = [
  {
    path: '/home',
    name: 'Dashboard',
    icon: <HomeIcon fontSize="medium" />,
    permission: RBACPermissions.DASHBOARD_ADMINISTRATOR,
  },
  {
    path: '/dashboard',
    name: 'Tablero',
    icon: <img src="/icon-home-tenant.svg" alt="icon" width={25} height={25} />,
    permission: RBACPermissions.DASHBOARD_TENANT,
  },

  /*TODO: Put on visible this after demo.                
  {
    path: '/analytics',
    name: 'Datos',
    icon: <SignalCellularAltIcon fontSize="medium" />,
    permission: RBACPermissions.TENANT_ANALYTICS,
  },*/
  {
    path: '/global-users',
    name: 'Usuarios globales',
    icon: <img src="/icon-users.svg" alt="icon" width={30} height={30} />,
    permission: RBACPermissions.TENANT_USERS,
  },

  {
    path: '/tenants',
    name: 'Tenants',
    icon: <img src="/icon-letter.svg" alt="icon" width={25} height={25} />,
    permission: RBACPermissions.TENANTS,
  },
  {
    path: '/comments',
    name: 'Comentarios',
    icon: <img src="/Icon-message.svg" alt="icon" width={25} height={25} />,
    permission: RBACPermissions.TENANT_COMMENTS,
  },
  {
    path: '/plans',
    name: 'Planes',
    icon: <PlanIcon htmlColor="#BBBBBB" width={25} height={25} />,
    permission: RBACPermissions.TENANT_PLANS,
  },
  // {
  //   path: '/plans',
  //   name: 'Planes',
  //   icon: <PaymentIcon fontSize="medium" />,
  //   permission: RBACPermissions.TENANT_PLANS,
  // },
  // {
  //   path: '/magazine',
  //   name: 'Magazine',
  //   icon: <img src="/ico-papper.png" alt="icon" width={25} height={25} />,
  //   permission: RBACPermissions.TENANT_MAGAZINES,
  // },
  {
    path: '/reports',
    name: 'Reportes',
    icon: <ReportIcon htmlColor="#BBBBBB" width={25} height={25} />,
    permission: RBACPermissions.SETTINGS,
  },
  {
    path: '/settings/account',
    name: 'Configuración',
    icon: <SettingsIcon fontSize="medium" color="inherit" />,
    permission: RBACPermissions.SETTINGS,
  },
];

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const SideMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { isBelow } = useBreakPoint('md');
  const { isOpen } = useStore(drawerSidebarEvent);
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles({
    drawerWidth: DRAWER_WIDTH,
    minDrawerWidth: MIN_DRAWER_WIDTH,
  });
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const { user, tenant } = useAuth();
  const TabItemCustom = styled(ListItem)({
    [`&.${listItemClasses.selected}, &:hover`]: {
      backgroundColor: tenant?.identityColor + '20' ?? 'black',
    },
  });
  return (
    <Drawer
      variant={isBelow ? 'temporary' : 'permanent'}
      anchor="left"
      open={isOpen}
      className={classes.drawer}
      onClose={() => openDrawerSidebar(!isOpen)}
      classes={{
        paper: classes.drawerPaper,
      }}>
      <Box
        display="flex"
        flexDirection={'column'}
        width={'100%'}
        height="100%"
        justifyContent="space-between"
        pb={1.5}>
        <Box>
          <Box display={'flex'} padding={'10px 0px'} justifyContent={'center'}>
            <img
              src={
                tenant && tenant.id
                  ? (tenant?.logo as string)
                  : '/digitalroom-logo.svg'
              }
              alt={'logo'}
              width={'30px'}
              height={'50px'}
              style={{ objectFit: 'contain', padding: '4px 0' }}
            />
          </Box>

          <Box
            width="100%"
            height="100%"
            display="flex"
            marginTop={2}
            justifyContent="space-between"
            flexDirection="column">
            <List style={{ padding: isOpen ? '0px 20px' : '0px 5px' }}>
              {ROUTES.map((route, index) => (
                <RoleAuthorizationAdmin
                  key={index}
                  render={() => (
                    <>
                      {index === ROUTES.length - 1 ? (
                        <Divider
                          orientation="horizontal"
                          style={{ marginTop: '15px', marginBottom: '20px' }}
                        />
                      ) : null}
                      <TabItemCustom
                        className={classes.listItem}
                        //button
                        sx={{ display: 'block', cursor: 'pointer' }}
                        onClick={() => navigate(route.path)}
                        selected={route.path === location.pathname}>
                        <ListItemIcon
                          sx={{
                            minWidth: 30,
                            mr: 0,
                            justifyContent: 'center',
                            marginRight: `${isOpen ? '5px' : '0px'}`,
                          }}>
                          {route.icon}
                        </ListItemIcon>
                        {isOpen && (
                          <ListItemText
                            primary={route.name}
                            sx={{ opacity: isOpen ? 1 : 0 }}
                          />
                        )}
                      </TabItemCustom>
                    </>
                  )}
                  error={() => null}
                  permission={route.permission}
                />
              ))}
            </List>
          </Box>
        </Box>
        <Box>
          <Divider orientation="horizontal" style={{ marginBottom: '5px' }} />
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={isOpen ? 'start' : 'center'}
            paddingX={isOpen ? 2 : 0}>
            <IconButton
              size="small"
              style={{
                borderRadius: '10px',
                width: isOpen ? '100%' : '',
                justifyContent: 'space-between',
              }}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit">
              <Box display={'flex'} alignItems={'center'}>
                <Avatar
                  sx={{
                    width: 35,
                    height: 35,
                    marginRight: isOpen ? '8px' : '0px',
                  }}
                  src={user?.avatar as string}
                />
                {isOpen ? (
                  <Box>
                    <Typography
                      marginBottom={'2px'}
                      fontSize={12}
                      textAlign={'start'}
                      color={'#BBBBBB'}>
                      Bienvenido 👋
                    </Typography>
                    <Typography fontSize={14}>
                      {decodeURI(user?.name as string)}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              {isOpen ? <ChevronRightIcon color="inherit" /> : null}
            </IconButton>
            <MuiMenu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem>
                <ListItemText>
                  Usuario:{' '}
                  <span className={classes.text}>
                    {decodeURI(user?.name as string)}
                  </span>{' '}
                </ListItemText>
              </MenuItem>
              <div className={classes.groupButton}>
                <MenuItem onClick={() => navigate('/settings/account')}>
                  Configuración
                </MenuItem>
              </div>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <PowerSettingsNewSharpIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText onClick={() => navigate('/logout')}>
                  Cerrar sesión
                </ListItemText>
              </MenuItem>
            </MuiMenu>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
/**
 * {isOpen ? (
            <Box textAlign="center" py={1} className={classes.versionText}>
              {' '}
              Version: 1.0.0-Beta
            </Box>
          ) : (
            <Box textAlign="center" py={1} className={classes.versionText}>
              <Box>Version</Box>
              <Box>1.0.0</Box>
              <Box>Beta</Box>
            </Box>
          )}
 */
