import React, { useEffect, useState } from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchComments } from './comment-actions';
import { fetchCommentsEvent } from './comment-events';
import { Typography, Grid, TablePagination, Container } from '@mui/material';
import { Loading } from '../../shared/components/Loading';
import { PostCommentItem } from './components/PostComentItem';
import { CustomHeader } from '../../shared/components/layout/CustomHeader';
import { useAuth } from '../auth/auth-hook';

export const Comments: React.FC = () => {
  const { comments, count } = useEvent(fetchCommentsEvent);
  const { user } = useAuth();
  const [callComments, loading] = useCallAction(fetchComments);
  const [pagination, setPagination] = useState({
    pageSize: 5,
    page: 1,
  });
  useEffect(() => {
    callComments(pagination.page - 1, pagination.pageSize);
  }, [callComments, pagination.page, pagination.pageSize]);

  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={'Este es el sistema EasyData donde puedes hacer un seguimiento de tu estrategia de membresías y/o registros.'}
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          background: 'white',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}
      >
        <Typography fontSize={{ sx: '16px', md: '20px' }} fontWeight={600}>
          Comentarios
        </Typography>

        <Grid container spacing={2} xs={12} paddingTop={2}>
          {loading ? (
            <Grid item xs={12}>
              <Loading height="300px">Cargando...</Loading>
            </Grid>
          ) : (
            comments.map((item, i) => (
              <Grid key={i} item xs={12} md={6}>
                <PostCommentItem post={item} />
              </Grid>
            ))
          )}
          <Grid xs={12}>
            <TablePagination
              count={count}
              component="div"
              page={pagination.page - 1}
              rowsPerPage={pagination.pageSize}
              onPageChange={(event: unknown, newPage: number) => {
                setPagination({
                  ...pagination,
                  page: newPage + 1,
                });
              }}
              rowsPerPageOptions={[]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
